import { LitteraProvider } from "@assembless/react-littera";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "../styles/style.css";
import App from "./WebApp";
import { AuthProvider } from "../sources/utility/Authentication";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import("../styles/fonts.scss");
import("../node_modules/bootstrap-icons/font/bootstrap-icons.css");

const queryClient = new QueryClient();

const initialLocale = location.pathname.match(/\/de\//) ? "de_DE" : "en_US";
ReactDOM.createRoot(document.getElementById("react-root")!).render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <LitteraProvider
        locales={["de_DE", "en_US"]}
        initialLocale={initialLocale}
      >
        <Router>
          <App></App>
        </Router>
      </LitteraProvider>
    </AuthProvider>
  </QueryClientProvider>,
);
